<template>
  <div>
    <a-page-header title="弹窗管理" class="hearStyle" />
    <a-form layout="inline">
      <a-form-item label="弹窗名称：">
        <a-input v-model="searchForm.popupName" placeholder="请输入" style="width: 200px" />
      </a-form-item>
      <a-form-item label="弹窗类型：">
        <a-select
          v-model="searchForm.typeId"
          style="width: 200px"
          placeholder="全部"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in statusList" :key="option.typeId" :value="option.typeId">
            {{ option.typeName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="状态：">
        <a-select
          v-model="searchForm.popupStatus"
          style="width: 200px"
          placeholder="全部"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option v-for="option in typeList" :key="option.value" :value="option.value">
            {{ option.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span class="btnClass">
        <a-button type="primary" @click="search" style="margin-top: 3px">
          <a-icon type="search" />
          查询
        </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm"> 重置 </a-button>
      </span>
    </a-form>
    <a-button class="mt-15" type="primary" @click="addData" v-if="$checkButtonPermissions($buttonPermissionsConfig.CONTENT_MANAGE.POPUP_ADMIN.ADD)"> <a-icon type="plus"/> 添加 </a-button>
    <a-table
      :columns="columns"
      style="margin-top: 10px"
      :data-source="tableData"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      :pagination="pagination.total ? pagination : false"
      @change="pageChange"
      :scroll="{ x: 1500 }"
      bordered
    >
      <span slot="linename" slot-scope="text, record" class="action">
        <a-tooltip>
          <template slot="title">
            {{ record.linename }}
          </template>
          <span class="field">{{ record.linename }}</span>
        </a-tooltip>
      </span>
      <span slot="action" slot-scope="text, record" class="action">
        <a-button type="link" @click="check(record)" v-if="$checkButtonPermissions($buttonPermissionsConfig.CONTENT_MANAGE.POPUP_ADMIN.LOOK)">查看</a-button>
        <a-button type="link" :disabled="record.popupStatus !== 1" @click="edmit(record)" v-if="$checkButtonPermissions($buttonPermissionsConfig.CONTENT_MANAGE.POPUP_ADMIN.EDIT)">编辑</a-button>
        <a-button type="link" v-show="record.popupStatus === 0" @click="changeTypeDisabled(record)" v-if="$checkButtonPermissions($buttonPermissionsConfig.CONTENT_MANAGE.POPUP_ADMIN.ENABLE)">禁用</a-button>
        <a-button type="link" v-show="record.popupStatus === 1" @click="changeTypeEnable(record)" v-if="$checkButtonPermissions($buttonPermissionsConfig.CONTENT_MANAGE.POPUP_ADMIN.ENABLE)">启用</a-button>
        <a-button
          type="link"
          :disabled="record.popupStatus !== 1"
          v-show="record.systemBounced !== 0"
          @click="deleteData(record)"
          v-if="$checkButtonPermissions($buttonPermissionsConfig.CONTENT_MANAGE.POPUP_ADMIN.DELETE)"
          >删除</a-button
        >
      </span>
    </a-table>
  </div>
</template>
<script>
import * as api from "@/api/popup";
export default {
  data() {
    return {
      statusList: [], //弹窗类型下拉值
      typeList: [
        //状态下拉值
        { label: "禁用", value: 1 },
        { label: "启用", value: 0 },
      ],
      searchForm: {
        typeId: undefined, //弹窗类型
        popupStatus: undefined, //状态
        popupName: "", //弹窗名称
      },
      //分页数据
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      columns: [
        { title: "ID", dataIndex: "showId", key: "showId", width: 100, align: "center" },
        { title: "弹窗名称", dataIndex: "popupName", key: "popupName", align: "center" },
        { title: "弹窗类型", dataIndex: "typeName", key: "typeName", align: "center" },
        { title: "生效时间", dataIndex: "timeSlot", key: "timeSlot", width: 350, align: "center" },
        { title: "优先级", dataIndex: "priority", key: "priority", align: "center" },
        {
          title: "状态",
          key: "popupStatus",
          dataIndex: "popupStatus",
          align: "center",
          customRender: function (text, record) {
            return (
              <div>
                <p>{record.popupStatus === 1 ? "禁用" : "启用"}</p>
              </div>
            );
          },
        },
      ],
      tableData: [],
      // 列表操作按钮权限配置
      actions: [
        this.$buttonPermissionsConfig.CONTENT_MANAGE.POPUP_ADMIN.LOOK,
        this.$buttonPermissionsConfig.CONTENT_MANAGE.POPUP_ADMIN.EDIT,
        this.$buttonPermissionsConfig.CONTENT_MANAGE.POPUP_ADMIN.ENABLE,
        this.$buttonPermissionsConfig.CONTENT_MANAGE.POPUP_ADMIN.DELETE
      ]
    };
  },
  mounted() {
    this.getTableList();
    this.getSelect();
  },
  methods: {
    // 获取弹窗类型下拉值
    async getSelect() {
      const res = await api.listall();
      if (res.code === "200") {
        this.statusList = res.data;
      }
    },
    //查看打开弹窗
    check(val) {
      // 判断弹窗类型是否是系统弹窗
      if (val.systemBounced === 0) {
        this.$router.push({
          path: "/popupAdmin/editPopup",
          query: { id: val.popupId, type: "1" },
        });
      } else {
        //非系统
        this.$router.push({
          path: "/popupAdmin/addPopup",
          query: { id: val.popupId, type: "0" },
        });
      }
    },
    //点击添加打开弹出
    addData() {
      this.$router.push({
        path: "/popupAdmin/addPopup",
      });
    },
    //编辑添加打开弹出
    edmit(val) {
      // 判断弹窗类型是否是系统弹窗
      if (val.systemBounced === 0) {
        this.$router.push({
          path: "/popupAdmin/editPopup",
          query: { id: val.popupId, type: "0" },
        });
      } else {
        //非系统
        this.$router.push({
          path: "/popupAdmin/addPopup",
          query: { id: val.popupId, type: "1" },
        });
      }
    },
    //删除
    deleteData(record) {
      const _this = this;
      _this.$confirm({
        title: `提示`,
        content: "是否确认删除该弹窗？确认后该弹窗数据将失效",
        async onOk() {
          const res = await api.deletepopupbypopupid({ popupId: record.popupId });
          if (res.code === "200") {
            _this.$message.success("删除成功!");
            _this.getTableList();
          } else {
            _this.$message.error(res.msg);
          }
        },
        onCancel() {},
      });
    },
    //筛选
    search() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getTableList();
    },
    // 筛选重置
    resetSearchForm() {
      this.searchForm = this.$options.data.call(this).searchForm;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.pagination.total = 0;
      this.getTableList();
    },
    //确认禁用
    changeTypeDisabled(record) {
      const _this = this;
      _this.$confirm({
        title: `提示`,
        content: "是否确认禁用？",
        async onOk() {
          const res = await api.disablepopupbypopupid({ popupId: record.popupId });
          if (res.code === "200") {
            _this.$message.success("禁用成功!");
            _this.getTableList();
          } else {
            _this.$message.error(res.msg);
          }
        },
        onCancel() {},
      });
    },
    //确认启用
    changeTypeEnable(record) {
      const _this = this;
      _this.$confirm({
        title: `提示`,
        content: "是否确认启用？",
        async onOk() {
          const res = await api.enablepopupbypopupid({ popupId: record.popupId });
          if (res.code === "200") {
            _this.$message.success("启用成功!");
            _this.getTableList();
          } else {
            _this.$message.error(res.msg);
          }
        },
        onCancel() {},
      });
    },
    //获取列表数据
    async getTableList() {
      let obj = {
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        ...this.searchForm,
      };
      const res = await api.popuplist(obj);
      if (res.code === "200") {
        //处理生效时间展示
        res.data.popupList.forEach((item) => {
          if (item.startTime) {
            item.timeSlot = item.startTime + " 至 " + item.endTime;
          }
        });
        this.tableData = res.data.popupList;
        this.pagination.total = res.data.total;
      }
    },
    //分页操作
    pageChange(option) {
      this.pagination.current = option.current;
      this.pagination.pageSize = option.pageSize;
      this.getTableList();
    },
  },
  created() {
    this.$checkTableActionIsShow(this.actions) && this.columns.push(this.$backTableAction({align: "center"}));
  }
};
</script>
<style lang="scss" scoped>
.mt-15 {
  margin-top: 15px;
}
.hearStyle {
  padding-left: 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(235, 237, 240);
}
.ant-table-row .ant-btn {
  padding: 0;
  margin: 0 5px;
}
p {
  margin-bottom: 0px;
}
</style>